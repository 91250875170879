import { Controller } from "@hotwired/stimulus";
import { put } from "@rails/request.js";

// Connects to data-controller="inline-editor"
export default class extends Controller {
  static targets = ["form", "content", "field"];

  connect() {}

  edit(ev) {
    this.contentTarget.style.display = "none";
    this.formTarget.classList.remove("d-none");
    this.formTarget.classList.add("d-flex");
    this.fieldTarget.focus();
  }

  cancel(ev) {
    ev.preventDefault();

    this.formTarget.classList.add("d-none");
    this.formTarget.classList.remove("d-flex");
    this.contentTarget.style.display = "block";
  }
}
