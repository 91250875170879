import { Controller } from "@hotwired/stimulus";
import { Sortable } from "sortablejs";
import { headers } from "../utils";

// Connects to data-controller="container"
export default class extends Controller {
  static targets = ["containers", "tiles", "container"];

  connect() {
    Sortable.create(this.containersTarget, {
      forceFallback: true,
      fallbackClass: "sortable-dragging",
      handle: ".move-container-trigger",
      scroll: true, // Enables automatic scrolling
      scrollSensitivity: 80, // Distance from the edge of the viewport at which to start scrolling
      scrollSpeed: 10, // Speed of the scroll

      onStart: () => {
        document.body.classList.toggle("sortable-active");

        this.containerTargets.forEach((el) => {
          el.classList.add("sortable-active");
        });
      },

      onEnd: (evt) => {
        document.body.classList.toggle("sortable-active");

        const trigger = new CustomEvent("sortable-complete");
        window.dispatchEvent(trigger);

        this.containerTargets.forEach((el) => {
          el.classList.remove("sortable-active");
        });

        const body = {
          container: {
            position: evt.newIndex,
          },
        };

        Turbo.fetch(evt.item.dataset.containerUrl, {
          method: "PUT",
          headers: headers(),
          body: JSON.stringify(body),
        }).catch((error) => {
          console.error("error", error);
        });
      },
    });
  }
}
