// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import "./controllers";
import "./google_picker";
import "./coloris";
import * as bootstrap from "bootstrap/dist/js/bootstrap.bundle";
import alertify from "alertifyjs";
import LocalTime from "local-time";
import "chartkick/chart.js";
import { Notyf } from "notyf";

LocalTime.start();

window.bootstrap = bootstrap;

alertify.defaults.transition = "slide";
alertify.defaults.theme.ok = "btn btn-primary";
alertify.defaults.theme.cancel = "btn btn-danger";
alertify.defaults.theme.input = "form-control";
import "./channels";

Element.prototype.insertChildAtIndex = function (child, position) {
  const tiles = this.querySelectorAll(
    ".tile-wrapper:not(.d-none):not(.no-drag)"
  );

  // position comes as a string
  const index = parseInt(position);

  // remember the number of tiles is one less since we removed one to reinsert
  if (tiles.length == 0 || tiles.length === index) {
    this.prepend(child);
  } else {
    this.insertBefore(child, tiles[index]);
  }
};

Turbo.StreamActions.redirect = function (ev) {
  const path = this.getAttribute("target");
  document.location.href = path;
};

Turbo.StreamActions.commentPosted = function (ev) {
  const notyf = new Notyf({
    duration: 2000,
    dismissible: true,
    position: { x: "left", y: "bottom" },
  });
  notyf.success("New comment posted");
};

Turbo.StreamActions.updateBackgroundUrl = function (ev) {
  const id = this.getAttribute("target");
  const backgroundUrl = this.getAttribute("backgroundUrl");
  document.getElementById(id).style.backgroundImage = `url(${backgroundUrl})`;
  document.getElementById(id).querySelector(".item-icon").remove();
};

Turbo.StreamActions.prependComments = function (ev) {
  const targetId = this.getAttribute("target");
  const target = document.getElementById(targetId);
  const content = this.querySelector("template").content;

  target.prepend(content);
  target.parentNode.scrollTop = 42;
};

Turbo.StreamActions.updateIdeaCommentCount = function (ev) {
  const targets = this.getAttribute("target");
  const content = this.querySelector("template").content;

  document.querySelectorAll(targets).forEach((el) => el.replaceWith(content));
};

Turbo.StreamActions.removeComment = function (ev) {
  const targets = this.getAttribute("target");

  document.querySelectorAll(targets).forEach((el) => el.remove());
};

Turbo.StreamActions.appendComment = function (ev) {
  const targetId = this.getAttribute("target");
  const target = document.getElementById(targetId);
  const commentId = this.getAttribute("commentId");

  // remove last timestamp
  document
    .querySelector("#comments div.comment-timestamp:last-of-type")
    ?.remove();

  // make sure it doesnt already exist
  const comment = document.getElementById(`comment_${commentId}`);

  if (!comment) {
    // target?.append(content);

    fetch(`/comments/${commentId}`, {
      method: "GET",
    })
      .then((resp) => resp.text())
      .then((html) => {
        const newElement = document.createElement("div");
        newElement.innerHTML = html;
        target?.append(newElement);
        newElement.scrollIntoView();
      })
      .catch((error) => {
        console.error("error", error);
      });
  }

  document.querySelector("#comments .comment:last-child")?.scrollIntoView();
};

Turbo.StreamActions.updateGrid = function (ev) {
  const session = Turbo.session;
  const requestId = this.getAttribute("requestId");
  const ideaId = this.getAttribute("ideaId");
  const position = this.getAttribute("position");
  const targetContainer = this.getAttribute("target");
  const content = this.querySelector("template").content;

  const isRecentRequest = requestId && session.recentRequests.has(requestId);

  if (!isRecentRequest && !session.navigator.currentVisit) {
    updateGrid(ideaId, position, content, targetContainer);
  } else {
  }
};

function updateGrid(ideaId, position, idea, targetContainer) {
  // gets it in any container, so will delete if moved to new container
  const tile = document.getElementById(`idea_${ideaId}`);

  if (tile) {
    // remove since we reinsert at the correct position
    tile.remove();
  }

  // insert.  uses new container id to ensure inserted in right place
  const tiles = document.getElementById(targetContainer);
  tiles?.insertChildAtIndex(idea, position);

  // deal with add block
  const addBlock = tiles?.querySelector(".add-block");
  addBlock?.classList.add("not-empty");
  addBlock?.classList.remove("empty");
}

import "trix"
import "@rails/actiontext"
