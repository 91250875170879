import { Controller } from "@hotwired/stimulus";
import { put } from "@rails/request.js";

// Connects to data-controller="task"
export default class extends Controller {
  static targets = ["name", "submit", "delete"];

  connect() {}

  focus() {
    this.submitTarget.style.display = "flex";
  }

  update(ev) {
    this.nameTarget.value = ev.target.innerText;
  }

  async checkbox(ev) {
    await put(this.element.action, {
      body: {
        task: {
          completed: !ev.params.complete,
        },
      },
      responseKind: "turbo-stream",
    });
  }
}
