import { Controller } from "@hotwired/stimulus";
import ActionSheet from "../modules/action_sheet";
import Cropper from "cropperjs";
import { headers } from "../utils";

// Connects to data-controller="cropper"
export default class extends Controller {
  static targets = [
    "image",
    "zoomModal",
    "replaceImage",
    "apply",
    "actionBtn",
    "footer",
    "footerBtn",
    "toolbar",
  ];
  static values = { url: String, projectUrl: String };

  connect() {
    this.originalImage = this.imageTarget.src;
  }

  show(ev) {
    this.modal = new bootstrap.Modal(this.zoomModalTarget, { backdrop: false });
    this.modal.show();

    this.zoomModalTarget.addEventListener("shown.bs.modal", (event) => {
      this.footerTarget.classList.remove("d-none");
      this.applyTarget.classList.add("d-none");
      this.actionBtnTargets.forEach((el) => el.classList.add("d-none"));

      // this.init();
    });
  }

  edit(ev) {
    this.footerBtnTargets.forEach((x) => (x.style.display = "none"));
    this.toolbarTarget.classList.remove("d-none");
    this.toolbarTarget.classList.add("d-flex");
    this.init();
  }

  init() {
    this.cropper = new Cropper(this.imageTarget, {
      dragMode: "move",
      autoCrop: false,
    });
  }

  crop(ev) {
    ev.preventDefault();

    const data = this.cropper.getCroppedCanvas().toDataURL("image/jpeg");
    this.cropper.replace(data);
  }

  reset(ev) {
    this.cropper.replace(this.originalImage);

    this.applyTarget.classList.add("d-none");
    this.actionBtnTargets.forEach((el) => el.classList.toggle("d-none"));
  }

  handler(ev) {
    const data = ev.currentTarget.dataset;

    if (data.method !== "image") {
      this.cropper[data.method](data.option, data.secondOption);
    }

    switch (data.method) {
      case "crop":
        this.actionBtnTargets.forEach((el) => el.classList.add("d-none"));
        return this.applyTarget.classList.remove("d-none");
      case "scaleX":
        this.actionBtnTargets.forEach((el) => el.classList.remove("d-none"));
        return (ev.currentTarget.dataset.option = -1 * data.option);
      case "scaleY":
        this.actionBtnTargets.forEach((el) => el.classList.remove("d-none"));
        return (ev.currentTarget.dataset.option = -1 * data.option);
      case "image":
        this.replaceImageTarget.click();

        return this.replaceImageTarget.addEventListener("change", (ev) => {
          this.cropper.replace(URL.createObjectURL(ev.target.files[0]));

          this.actionBtnTargets.forEach((el) => el.classList.remove("d-none"));
        });
      default:
        return this.actionBtnTargets.forEach((el) =>
          el.classList.remove("d-none")
        );
    }
  }

  apply(ev) {
    const data = this.cropper.getCroppedCanvas().toDataURL("image/jpeg");
    this.cropper.replace(data);

    this.applyTarget.classList.add("d-none");
    this.actionBtnTargets.forEach((el) => el.classList.toggle("d-none"));
  }

  save(ev) {
    ev.preventDefault();

    const as = new ActionSheet({
      title: "Save Changed",
      text: `This will overwrite the existing image for this item. This action cannot be undone.`,
    });

    as.yesNoMenu(() => {
      this.update();
    });
  }

  update() {
    window.notyf.success("Saving...");
    this.footerTarget.classList.add("d-none");

    const data = this.cropper.getCroppedCanvas().toDataURL("image/jpeg");
    const body = {
      item: {
        media_url: data,
      },
    };

    fetch(this.urlValue, {
      method: "PUT",
      headers: headers(),
      body: JSON.stringify(body),
    })
      .then((resp) => resp.text())
      .then((html) => {
        window.notyf.dismissAll();
        window.notyf.success("Saved!");
        this.modal.hide();

        Turbo.renderStreamMessage(html);
      })
      .catch((error) => {
        this.footerTarget.classList.remove("d-none");
        console.error("error", error);
      });
  }
}
