// auto_grow_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.resize();
  }

  resize() {
    // const height = this.element.scrollHeight;
    // need to add 2 to prevent scrollbar from appearing
    this.element.style.height = "auto";
    this.element.style.height = this.element.scrollHeight + 2 + "px";
    this.element.style.minHeight = "1rem";
  }

  input() {
    this.resize();
  }
}
