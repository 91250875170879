import { Controller } from "@hotwired/stimulus";
import { headers } from "../utils";

// Connects to data-controller="inline-editor"
export default class extends Controller {
  static values = { url: String, object: String, attribute: String };

  connect() {
    this.intialValue = this.element.innerText;

    this.focus = this.focus.bind(this);
    this.blur = this.blur.bind(this);
    this.keypress = this.keypress.bind(this);

    this.element.addEventListener("focus", this.focus);
  }

  focus(ev) {
    this.element.removeEventListener("focus", this.focus);

    var range, selection;
    if (document.body.createTextRange) {
      range = document.body.createTextRange();
      range.moveToElementText(this.element);
      range.select();
    } else if (window.getSelection) {
      selection = window.getSelection();
      range = document.createRange();
      range.selectNodeContents(this.element);
      selection.removeAllRanges();
      selection.addRange(range);
    }

    this.element.addEventListener("blur", this.blur);
    this.element.addEventListener("keydown", this.keypress);
  }

  blur(ev) {
    this.save(ev);
  }

  keypress(ev) {
    const key = ev.which || ev.keyCode;

    if (key === 13) {
      ev.preventDefault();
      this.save(ev);
    }

    if (key === 27) {
      this.element.innerText = this.intialValue;
      this.element.removeEventListener("blur", this.blur);
      this.element.removeEventListener("keydown", this.keypress);

      window.getSelection().removeAllRanges();
      this.element.blur();

      this.element.addEventListener("focus", this.focus);
    }
  }

  save(ev) {
    this.element.removeEventListener("blur", this.blur);
    this.element.removeEventListener("keydown", this.keypress);

    const val = this.element.textContent.replace(/(\r\n|\n|\r)/gm, " ").trim();

    const body = {};
    body[this.objectValue] = {};
    body[this.objectValue][this.attributeValue] = val;

    if (val.trim() !== "") {
      Turbo.fetch(this.urlValue, {
        method: "PUT",
        headers: headers(),
        body: JSON.stringify(body),
      }).then((resp) => {
        if (!resp.ok) {
          return resp.text().then((html) => {
            Turbo.renderStreamMessage(html);
            this.element.innerText = this.intialValue;
            this.element.blur();
            this.element.addEventListener("focus", this.focus);

            if (resp.status == 409) {
              window.notyf.error({
                message: "This name is already taken",
                dismissable: true,
              });
            }
          });
        } else {
          resp.text().then((html) => {
            Turbo.renderStreamMessage(html);
            this.element.blur();
            this.element.addEventListener("focus", this.focus);
          });
        }
      });
    } else {
      this.element.innerText = this.intialValue;
      this.element.blur();
      this.element.addEventListener("focus", this.focus);
    }
  }
}
