import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="signup"
export default class extends Controller {
  static targets = ["companyDiv", "options", "btn"];
  static values = { active: Boolean };

  connect() {}

  btnClicked(ev) {
    if (!this.activeValue) {
      ev.preventDefault();

      this.optionsTarget.classList.add("alert");
      this.optionsTarget.classList.add("alert-danger");

      window.notyf.error({
        message: "Please make a selection",
        dismissible: true,
      });
    }
  }

  selected() {
    // this.btnTarget.disabled = false;
    this.activeValue = true;
    this.btnTarget.classList.remove("btn-secondary");
    this.btnTarget.classList.add("btn-primary");
    this.optionsTarget.classList.remove("alert");
    this.optionsTarget.classList.remove("alert-danger");
  }

  hideCompanyDiv() {
    this.companyDivTarget.classList.remove("show");
  }
}
