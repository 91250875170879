import VanillaContextMenu from "vanilla-context-menu";

export default class ScrollableContextMenu extends VanillaContextMenu {
  #lastMenuPosition = { x: 0, y: 0 };
  #scrollHandler = null;
  #scrollContainer = null;
  #initialScrollPosition = { x: 0, y: 0 };
  #clickedElement = null;
  #requestAnimationFrameId = null;
  #menuOffset = { x: 0, y: 0 };

  constructor(options) {
    super(options);

    this.#scrollContainer = document.querySelector("#canvas");
    if (!this.#scrollContainer) {
      console.error("Scroll container #canvas not found");
      return;
    }

    const originalHandler = this.options.scope.oncontextmenu;
    this.options.scope.oncontextmenu = (event) => {
      // Store the clicked element
      this.#clickedElement = event.target;

      // Get the element's position
      const elementRect = this.#clickedElement.getBoundingClientRect();

      // Store the initial position relative to the container
      const containerRect = this.#scrollContainer.getBoundingClientRect();
      this.#lastMenuPosition = {
        x: event.clientX - containerRect.left,
        y: event.clientY - containerRect.top,
      };

      // Store the offset between click position and element position
      this.#menuOffset = {
        x: event.clientX - elementRect.left,
        y: event.clientY - elementRect.top,
      };

      // Store initial scroll position
      this.#initialScrollPosition = {
        x: this.#scrollContainer.scrollLeft,
        y: this.#scrollContainer.scrollTop,
      };

      // Call original handler
      originalHandler(event);

      // Setup scroll handling after menu is shown
      this.#setupScrollHandler();
    };
  }

  #updateMenuPosition = () => {
    const contextMenu = document.querySelector(
      ".cm-bootstrap:not(.nested-context-menu)"
    );

    if (!contextMenu || !this.#clickedElement) {
      if (this.#requestAnimationFrameId) {
        cancelAnimationFrame(this.#requestAnimationFrameId);
      }
      return;
    }

    // Get the clicked element's current position
    const elementRect = this.#clickedElement.getBoundingClientRect();

    // Calculate menu position based on element position and original offset
    const x = elementRect.left + this.#menuOffset.x;
    const y = elementRect.top + this.#menuOffset.y;

    // Apply position directly without boundary checking
    contextMenu.style.transform = `translate3d(${x}px, ${y}px, 0)`;
    contextMenu.style.position = "fixed";
    contextMenu.style.left = "0";
    contextMenu.style.top = "0";

    // Update nested menus
    const nestedMenu = document.querySelector(
      ".cm-bootstrap.nested-context-menu"
    );
    if (nestedMenu) {
      const parentMenuItem = nestedMenu.previousElementSibling;
      if (parentMenuItem) {
        const rect = parentMenuItem.getBoundingClientRect();
        const nestedWidth = nestedMenu.offsetWidth;
        const containerRect = this.#scrollContainer.getBoundingClientRect();

        let nestedX =
          rect.right + nestedWidth > containerRect.right
            ? rect.left - nestedWidth
            : rect.right;

        nestedMenu.style.transform = `translate3d(${nestedX}px, ${rect.top}px, 0)`;
        nestedMenu.style.position = "fixed";
        nestedMenu.style.left = "0";
        nestedMenu.style.top = "0";
      }
    }

    // Request next frame
    this.#requestAnimationFrameId = requestAnimationFrame(
      this.#updateMenuPosition
    );
  };

  #setupScrollHandler() {
    // Clean up any existing handler
    if (this.#scrollHandler) {
      this.#scrollContainer.removeEventListener("scroll", this.#scrollHandler);
      if (this.#requestAnimationFrameId) {
        cancelAnimationFrame(this.#requestAnimationFrameId);
      }
    }

    // Start the animation frame loop
    this.#requestAnimationFrameId = requestAnimationFrame(
      this.#updateMenuPosition
    );

    // Setup scroll handler just to detect scroll events
    this.#scrollHandler = () => {
      // The actual position update happens in the animation frame
    };

    this.#scrollContainer.addEventListener("scroll", this.#scrollHandler, {
      passive: true,
    });
  }

  off() {
    super.off();
    if (this.#scrollHandler) {
      this.#scrollContainer.removeEventListener("scroll", this.#scrollHandler);
      this.#scrollHandler = null;
    }
    if (this.#requestAnimationFrameId) {
      cancelAnimationFrame(this.#requestAnimationFrameId);
      this.#requestAnimationFrameId = null;
    }
    this.#clickedElement = null;
  }
}
