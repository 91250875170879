import { Controller } from "@hotwired/stimulus";
import { jsonHeaders, headers, fadeOut } from "../utils";
// import VanillaContextMenu from "vanilla-context-menu";
import ActionSheet from "../modules/action_sheet";
import ScrollableContextMenu from "../modules/scrollable_context_menu";

// Connects to data-controller="tile"
export default class extends Controller {
  static targets = ["tile", "commentTextarea"];

  static values = {
    url: String,
    itemUrl: String,
    containersUrl: String,
    shareUrl: String,
    copyUrl: String,
    labelsUrl: String,
    exportUrl: String,
    type: String,
    boardId: String,
    mediaUrl: String,
    name: String,
    itemLabelsUrl: String,
    pinnedLabelIds: Array,
    associatedId: Number,
    leftClickActive: Boolean,
  };

  connect() {
    this.buildContextMenu();
    this.setupListeners();

    if (this.leftClickActiveValue) {
      this.leftClickMenu();
    }
  }

  setupListeners() {
    // if (!this.listenValue) return;

    this.element.querySelectorAll("[data-vt-trigger").forEach((el) => {
      el.style.cursor = "pointer";
      el.onclick = (ev) => {
        const triggers = el.dataset.vtTrigger.split(",");
        if (triggers.includes(document.body.dataset.view)) {
          this.show(ev);
        }
      };
    });
  }

  show(ev) {
    ev.preventDefault();

    // check for presentation view
    let url =
      document.body.dataset.view == "presentation"
        ? this.itemUrlValue
        : this.urlValue;

    if (ev.params?.comments) {
      const params = new URLSearchParams({ comments: "true" });
      url = `${url}${url.includes("?") ? "&" : "?"}${params.toString()}`;
    }

    fetch(url, {
      method: "GET",
      headers: headers(),
    })
      .then((resp) => resp.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);

        document.body.classList.add("info-panel-active");
        document.body
          .querySelector("#canvas .overlay")
          ?.addEventListener("click", this.close);
      })
      .catch((error) => {
        console.error("error", error);
      });
  }

  async update(idea) {
    const body = {
      idea: idea,
    };

    fetch(this.urlValue + ".json", {
      method: "PUT",
      headers: jsonHeaders(),
      body: JSON.stringify(body),
    })
      .then((resp) => {
        if (!resp.ok) {
          resp.json().then((json) => {
            window.notyf.error(json.errors);
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        window.notyf.error(err);
      });
  }

  async delete() {
    fetch(this.urlValue, {
      method: "DELETE",
      headers: headers(),
    })
      .then((resp) => resp.text())
      .then((html) => {
        // if (this.leftClickActiveValue) {
        document.body.classList.remove("info-panel-active");
        // }

        Turbo.renderStreamMessage(html);
      })
      .catch((err) => {
        window.notyf.errors(err);
      });
  }

  move(ev) {}

  buildContextMenu() {
    this.contextMenu = new ScrollableContextMenu({
      scope: this.element,
      customThemeClass: "cm-bootstrap",
      transitionDuration: 0,
      menuItems: this.menuItems(),
      preventCloseOnClick: true,
    });
  }

  menuItems() {
    const menuItems = [];

    if (this.typeValue == "item") {
      menuItems.push({
        label: "Zoom",
        iconClass: "fa-regular fa-magnifying-glass",
        callback: () => {
          this.contextMenu?.close();

          const modalDiv = document.createElement("div");
          modalDiv.className = "modal fade";
          modalDiv.id = "imageModal";
          modalDiv.tabIndex = -1;
          modalDiv.setAttribute("aria-labelledby", "imageModalLabel");
          modalDiv.setAttribute("aria-hidden", "true");

          modalDiv.innerHTML = `
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">${this.nameValue}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body text-center">
                            <img src="${this.mediaUrlValue}" class="img-fluid rounded" alt="Zoomed image">
                        </div>
                    </div>
                </div>
            `;

          document.body.appendChild(modalDiv);

          const modal = new bootstrap.Modal(modalDiv);
          modal.show();

          modalDiv.addEventListener("hidden.bs.modal", function () {
            document.body.removeChild(modalDiv);
          });
        },
      });

      menuItems.push("hr");

      menuItems.push({
        label: "Copy",
        iconClass: "fa-regular fa-copy",
        callback: () => {
          this.contextMenu?.close();

          fetch(this.copyUrlValue, {
            headers: headers(),
          })
            .then((resp) => resp.text())
            .then((html) => Turbo.renderStreamMessage(html))
            .catch((err) => {
              window.notyf.errors(err);
            });
        },
      });
    }

    menuItems.push({
      label: "Move",
      iconClass: "fa-regular fa-file-export",
      callback: () => {
        this.buildContainersMenu();
      },
      nestedMenu: [],
    });

    if (this.typeValue == "item") {
      menuItems.push("hr");
      menuItems.push(this.statusLabels());
    }

    if (this.typeMenu()) {
      menuItems.push("hr");
      menuItems.push(this.typeMenu());

      if (this.typeValue == "item") {
        menuItems.push(this.tearSheet());
      }

      menuItems.push("hr");
    }

    if (document.body.dataset.view != "gallery") {
      menuItems.push(
        {
          label: "Resize",
          iconClass: "fa-regular fa-magnifying-glass",
          callback: () => {},
          nestedMenu: [
            {
              label: "1",
              callback: () => {
                const level = 1;
                // this.element.dataset.zoom = level;
                this.update({ col_span: level });
                this.contextMenu?.close();
              },
            },
            {
              label: "2",
              callback: () => {
                const level = 2;
                // this.element.dataset.zoom = level;
                this.update({ col_span: level });
                this.contextMenu?.close();
              },
            },
            {
              label: "3",
              callback: () => {
                const level = 3;
                // this.element.dataset.zoom = level;
                this.update({ col_span: level });
                this.contextMenu?.close();
              },
            },
          ],
        },
        "hr"
      );
    }

    menuItems.push({
      label: this.deletableValue ? "Delete" : "Remove",
      callback: () => {
        if (this.typeValue == "list") {
          const as = new ActionSheet({
            title: "Delete List",
            text: `Are you sure you want to delete this list? This action cannot be undone.`,
          });

          as.yesNoMenu(() => {
            this.contextMenu.close();
            this.delete();
          });
        } else {
          this.contextMenu.close();
          this.delete();
        }
      },
      iconClass: "fa fa-trash", // this only works if you have FontAwesome icons
    });

    return menuItems;
  }

  buildContainersMenu() {
    const observer = this.onNestedContextMenuCreated(async (target) => {
      observer.disconnect();

      target.innerHTML =
        "<div class='placeholder-glow flex-column'><div class='placeholder w-100 mb-1 rounded'></div><div class='placeholder w-100 mb-1 rounded'></div><div class='placeholder w-100 mb-1 rounded'></div></div>";

      const resp = await fetch(this.containersUrlValue);
      const containers = await resp.json();

      target.innerHTML = "";

      containers.forEach((c) => {
        const div = document.createElement("div");
        div.className = "cursor-pointer";
        div.innerHTML = `<span></span><span>${c.label}</span>`;
        div.onclick = (ev) => {
          this.update({ container_id: c.id });
          this.contextMenu?.close();
          // this.element.remove();
          fadeOut(this.element);
        };
        target.append(div);
      });
    });
  }

  buildLabelsMenu() {
    const observer = this.onNestedContextMenuCreated(async (target) => {
      observer.disconnect();

      target.innerHTML =
        "<div class='placeholder-glow flex-column'><div class='placeholder w-100 mb-1 rounded'></div><div class='placeholder w-100 mb-1 rounded'></div><div class='placeholder w-100 mb-1 rounded'></div></div>";

      const resp = await fetch(this.labelsUrlValue);
      const labels = await resp.json();

      target.innerHTML = "";

      if (labels.length == 0) {
        const div = document.createElement("div");
        div.className = "cursor-pointer";
        div.innerHTML = `<span><i class="fa-kit fa-solid-tag-slash"></i></span><span>No board labels found</span>`;
        target.append(div);
      } else {
        let pinnedIds = [...new Set(this.pinnedLabelIdsValue)];
        labels.forEach((label) => {
          // check if already exists
          const assigned = pinnedIds.includes(label.id);

          const div = document.createElement("div");
          div.className = "cursor-pointer";
          div.innerHTML = `<span class="p-2 rounded-circle me-2 border" style="background-color: ${
            label.color
          }"></span><span class="${assigned && "fw-bold"}">${
            label.name
          }</span>`;
          div.onclick = (ev) => {
            // only add it if not assigned else remove it
            if (assigned) {
              pinnedIds = pinnedIds.filter((item) => item !== label.id);
            } else {
              pinnedIds.push(label.id);
            }

            // need to add that label to the item first
            const body = {
              item_label: {
                label_ids: pinnedIds,
              },
            };

            fetch(this.itemLabelsUrlValue, {
              method: "POST",
              headers: jsonHeaders(),
              body: JSON.stringify(body),
            })
              .then((resp) => resp.json())
              .then((json) => {
                this.update({
                  pinned_label_ids: pinnedIds,
                });
                this.contextMenu?.close();
              })
              .catch((error) => {
                window.notyf.error({
                  message: `Error assigning labels - ${error}`,
                  dismissible: true,
                });

                this.contextMenu?.close();
              });
          };

          target.append(div);
        });
      }
    });
  }

  onNestedContextMenuCreated(callback) {
    // Define the mutation observer
    const observer = new MutationObserver((mutationsList, observer) => {
      for (const mutation of mutationsList) {
        // Check if there are added nodes
        if (mutation.addedNodes.length > 0) {
          mutation.addedNodes.forEach((node) => {
            // Check if the added node is an element and has the desired class
            if (
              node.nodeType === Node.ELEMENT_NODE &&
              node.classList.contains("nested-context-menu")
            ) {
              // Call the callback with the newly created element
              callback(node);

              // check positioning
              const nodeRight = node.offsetLeft + node.offsetWidth;
              if (nodeRight > document.body.offsetWidth) {
                const buffer = 16;
                const diff = nodeRight - document.body.offsetWidth;
                const newLeft = node.offsetLeft - diff - buffer;
                node.style.left = `${newLeft}px`;
              }
            }
          });
        }
      }
    });

    // Start observing the entire document for childList changes in the DOM
    observer.observe(document.body, { childList: true, subtree: true });

    // Return the observer instance so it can be stopped later
    return observer;
  }

  statusLabels() {
    return {
      label: "Labels",
      iconClass: "fa-regular fa-tag",
      callback: () => {
        this.buildLabelsMenu();
      },
      nestedMenu: [],
    };
  }

  tearSheet() {
    return {
      label: "Tear Sheet",
      iconClass: "fa-regular fa-file-pdf",
      callback: () => {
        fetch(this.exportUrlValue, {
          headers: headers(),
        })
          .then((resp) => resp.text())
          .then((html) => {
            this.contextMenu.close();
            Turbo.renderStreamMessage(html);
          });
      },
    };
  }

  typeMenu() {
    if (this.typeValue == "item") {
      return {
        label: "Share",
        iconClass: "fa-regular fa-share",
        callback: () => {
          this.contextMenu?.close();
          fetch(this.shareUrlValue, {
            headers: headers(),
          })
            .then((resp) => resp.text())
            .then((html) => Turbo.renderStreamMessage(html));
        },
      };
    }

    if (this.typeValue == "website") {
      return {
        label: "Edit Website",
        iconClass: "fa-regular fa-pencil",
        callback: () => {
          this.contextMenu?.close();
          fetch(
            `/app/${this.boardIdValue}/websites/${this.associatedIdValue}/edit`,
            {
              headers: headers(),
            }
          )
            .then((resp) => resp.text())
            .then((html) => Turbo.renderStreamMessage(html));
        },
      };
    }

    if (this.typeValue == "note") {
      const colors = [
        {
          hexValue: "#03A9F4",
          name: "Light Blue",
        },
        {
          hexValue: "#4CAF50",
          name: "Green",
        },
        {
          hexValue: "#8BC34A",
          name: "Light Green",
        },
        {
          hexValue: "#CDDC39",
          name: "Lime",
        },
        {
          hexValue: "#FFEB3B",
          name: "Yellow",
        },
        {
          hexValue: "#FFC107",
          name: "Amber",
        },
        {
          hexValue: "#FF9800",
          name: "Orange",
        },
        {
          hexValue: "#9E9E9E",
          name: "Grey",
        },
      ];

      const colorMenu = [];
      colors.forEach((color) => {
        colorMenu.push({
          label: color.name,
          callback: () => {
            this.tileTarget.style.backgroundColor = color.hexValue;
            this.update({
              note_attributes: {
                id: this.associatedIdValue,
                background_color: color.hexValue,
              },
            });
          },
        });
      });

      return {
        label: "Color",
        iconClass: "fa-regular fa-droplet",
        callback: () => {},
        nestedMenu: colorMenu,
      };
    }
  }

  leftClickMenu() {
    // Remove the default context menu binding
    this.contextMenu.options.scope.oncontextmenu = null;

    this.contextMenu.options.scope.onclick = (e) => {
      e.preventDefault();
      e.stopPropagation();

      // Store the event as if it was a context menu event
      this.contextMenu.initialContextMenuEvent = e;

      // Remove any existing root menus
      const existingMenu = document.querySelector(
        ".cm-bootstrap:not(.nested-context-menu)"
      );
      if (existingMenu) {
        existingMenu.remove();
      }

      // Let the library handle building the menu and attaching handlers
      const menuElement = this.contextMenu.buildContextMenu();

      // Set initial styles for animation
      menuElement.style.transform = "scale(0)";
      menuElement.style.transition = "transform 20ms ease-in-out";

      // Apply custom theme class
      menuElement.classList.add("cm-bootstrap");

      document.querySelector("body").append(menuElement);

      // Viewport padding
      const VIEWPORT_PADDING = 10;

      // Get viewport dimensions with padding
      const viewportWidth = window.innerWidth - VIEWPORT_PADDING;
      const viewportHeight = window.innerHeight - VIEWPORT_PADDING;

      // Get menu dimensions
      const menuWidth = menuElement.offsetWidth;
      const menuHeight = menuElement.offsetHeight;

      // Calculate position to keep menu in viewport
      let x = e.clientX;
      let y = e.clientY;

      // Check if menu would go off right side
      const isRightAligned = x + menuWidth > viewportWidth;

      // Adjust horizontal position if menu would go off-screen
      if (isRightAligned) {
        x = Math.max(VIEWPORT_PADDING, viewportWidth - menuWidth);
      }

      // Adjust vertical position if menu would go off-screen
      if (y + menuHeight > viewportHeight) {
        y = Math.max(VIEWPORT_PADDING, y - menuHeight);
      }

      // Position the menu
      menuElement.style.top = `${y}px`;
      menuElement.style.left = `${x}px`;

      // Have the library set up all menu event handlers
      this.contextMenu.options.menuItems.forEach((item, index) => {
        if (item !== "hr" && item.callback) {
          const menuItem = menuElement.children[index];
          menuItem.onclick = () => {
            // Handle nested menus
            if (item.nestedMenu) {
              // Wait for nested menu to be created
              setTimeout(() => {
                const nestedMenu = document.querySelector(
                  ".cm-bootstrap.nested-context-menu"
                );
                if (nestedMenu) {
                  // Get position relative to parent menu item
                  const rect = menuItem.getBoundingClientRect();
                  const nestedWidth = nestedMenu.offsetWidth;

                  // If parent menu is right-aligned, show nested menu to the left
                  if (isRightAligned) {
                    nestedMenu.style.left = `${rect.left - nestedWidth}px`;
                  } else {
                    nestedMenu.style.left = `${rect.right}px`;
                  }
                  nestedMenu.style.top = `${rect.top}px`;

                  // Apply custom theme class to nested menu
                  nestedMenu.classList.add("cm-bootstrap");

                  // Animate nested menu
                  nestedMenu.style.transform = "scale(0)";
                  nestedMenu.style.transition = "transform 20ms ease-in-out";
                  setTimeout(() => {
                    nestedMenu.style.transform = "scale(1)";
                  }, 0);
                }
              }, 0);
            }

            item.callback(e);
            // Close menu unless explicitly prevented
            if (
              !item.preventCloseOnClick &&
              !this.contextMenu.options.preventCloseOnClick
            ) {
              document
                .querySelectorAll(".cm-bootstrap")
                .forEach((menu) => menu.remove());
            }
          };
        }
      });

      // Prevent right-click on menu
      menuElement.oncontextmenu = (e) => e.preventDefault();

      // Show the menu with animation
      setTimeout(() => {
        menuElement.style.transform = "scale(1)";
      }, 0);
    };

    // Update click-outside handler
    document.addEventListener("click", (e) => {
      if (!e.target.closest(".cm-bootstrap")) {
        document
          .querySelectorAll(".cm-bootstrap")
          .forEach((menu) => menu.remove());
      }
    });
    // END
  }

  commentSubmit(ev) {
    this.commentTextareaTarget.value = "";
  }
}
